import { convertTime, formatDate, formatPhoneNumber } from "../../Common/utils/extraFunctions";
import { validateEmail } from "../../Common/commondata/commonData";
export const formatPrice = (price, source) => {
  if (!price) {
    return source ? "0.00" : "";
  }
  const cleanedPrice = price.toString()?.replace(/[^0-9.-]/g, ""); //allowing the minus sign also
  // const cleanedPrice = price.toString()?.replace(/[^0-9.]/g, '')
  const numericPrice = parseFloat(cleanedPrice);
  if (isNaN(numericPrice) || !numericPrice) {
    return source ? "0.00" : "";
  }
  const priceString = numericPrice.toString();
  const hasDecimal = priceString.includes(".");
  // Adding a minus sign for negative values
  const formattedPrice =
    (numericPrice < 0 ? "-" : "") +
    (hasDecimal
      ? Math.abs(numericPrice)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : Math.abs(numericPrice)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".00");
  return formattedPrice;
};

export const checkAllRequiredFields = (CurrentPdfImportData) => {
  if (!Object.keys(CurrentPdfImportData)?.length) return false;
  const requiredFields = ["Buyers", "FullAddress", "PurchasePrice", "EarnestMoney", "CashAtClosing", "PossessionDate", "ClosingDate", "AcceptanceDeadlineDate"];

  // checking if all required fields are present and have non-empty values
  for (const field of requiredFields) {
    if (!CurrentPdfImportData.hasOwnProperty(field) || (Array.isArray(CurrentPdfImportData[field]) && CurrentPdfImportData[field].length === 0) || !CurrentPdfImportData[field]) {
      return false;
    }
  }
  isAtleastOneClientPresent(CurrentPdfImportData.Buyers);

  return true;
};

export const isAtleastOneClientPresent = (clients) => {
  if (Array.isArray(clients) && clients.length > 0) {
    for (const client of clients) {
      if (client.isCorp && client.signers.length > 0) {
        return true;
      } else if (!client.isCorp && client.fullName) {
        return true;
      }
    }
  }
  return false;
};

export const getFullNamesFromArray = (personsArray) => {
  if (!Array.isArray(personsArray)) return "";
  return personsArray
    .filter((person) => person && typeof person === "object" && person.fullName)
    .map((person) => person.fullName.trim())
    .join(", ");
};
export const getCombinedEmailsFromArray = (personsArray) => {
  if (!Array.isArray(personsArray)) return "";
  return personsArray
    .filter((person) => person && typeof person === "object" && person.email)
    .map((person) => person.email.trim())
    .join(", ");
};

export const getCombinedFullNameAndEmailsFromArray = (personsArray) => {
  if (!Array.isArray(personsArray)) return "";
  const listItems = personsArray
    .filter((person) => person && typeof person === "object" && (person.fullName || person.email))
    .map((person) => {
      const name = person.fullName ? person.fullName.trim() : "No Name";
      const email = person.email ? person.email.trim() : "No Email";
      return `<li style="padding-left: 0px;">
      <span style="color: black;">${name}</span> <span style="font-weight: bold;"> - </span> 
      <span style="color: blue;">${email}</span>
    </li>`;
    })
    .join("");
  return listItems ? `<ul style="margin: 0px;">${listItems}</ul>` : "";
};
export const handleCheckboxGroupUpdate = (selectedValue, cbGroup, specificData) => {
  document.querySelectorAll(`[data-cb-group="${cbGroup}"]`)?.forEach((element) => {
    if (element.value !== selectedValue && element.checked) {
      // console.log("setting checked on", element.name, element.value, " to false");
      element.checked = false;
      if (element.hasAttribute("data-enable-source")) {
        // console.log("clearing input of checkbox target");
        document.querySelectorAll(`[data-enable-target="${element.dataset?.enableSource}"]`).forEach((ele) => {
          if (ele.type !== "checkbox") {
            // console.log("target is not a checkbox. clearing value");
            ele.value = "";
            specificData[ele.name] = "";
            if (ele.parentNode.classList.contains("grow-with-input") || ele.parentNode.classList.contains("grow-with-content")) {
              ele.parentNode.dataset.replicatedValue = "";
            }
          } else {
            // target is a checkbox
            // console.log("target is checkbox to turn off");
            ele.checked = false;
            specificData[ele.name] = "";
          }
        });
      }

      if (element.hasAttribute("data-linked-value")) {
        // console.log("clearing input of checkbox linked value");
        document.querySelectorAll(`[data-linked-value="${element.name}"][name="${element.dataset.linkedValue}"]`).forEach((ele) => {
          ele.value = "";
          specificData[ele.name] = "";
          if (ele.parentNode.classList.contains("grow-with-input") || ele.parentNode.classList.contains("grow-with-content")) {
            ele.parentNode.dataset.replicatedValue = "";
          }
        });
      }
    }
  });
};


export const setInitialValues = ({ CurrentPdfImportData, clientsArray, agentsArray }) => {
  let td = CurrentPdfImportData;
  if (td && Object.keys(td)?.length > 0) {
    for (const key in td) {
      const savedValue = td[key];
      if (key === "Buyers" && Array.isArray(clientsArray)) {
        const buyerStr = getFullNamesFromArray([...clientsArray]);
        const elements = document.querySelectorAll("[data-buyers-list]");
        // console.log("elements", elements);
        if (elements) {
          elements.forEach((element) => {
            element.innerHTML = buyerStr;
            element.parentNode.dataset.replicatedValue = buyerStr;
          });
        }
      } else if (key === "BuyerAgents") {
        const nameEmailStr = getCombinedFullNameAndEmailsFromArray(savedValue);
        const emailElements = document.querySelectorAll("[data-buyer-agents-with-email-list]");
        if (emailElements) {
          emailElements.forEach((element) => {
            element.innerHTML = nameEmailStr;
            element.parentNode.dataset.replicatedValue = nameEmailStr;
          });
        }
      } else {
        if (Array.isArray(td[key])) {
          td[key].forEach((value) => {
            // console.log("checking if", value, " is selected");
            document.getElementsByName(key).forEach((element, i) => {
              if (element.value === value) {
                element.checked = true;
              }
              if (element.type === "checkbox") {
                if (element.value === td[key]) {
                  element.checked = true;
                }
              }
            });
          });
        } else if (typeof savedValue === "object") {
          const elements = document.getElementsByName(key);
          if (elements) {
            elements.forEach((element, i) => {
              if (element.type !== "checkbox") {
                if (savedValue && savedValue.type === "date") {
                  element.value = formatDate(savedValue.value);
                } else if (savedValue && savedValue?.type === "mec") {
                  element.value = `${savedValue.value} days after MEC`;
                } else if (savedValue && savedValue.type === "text") {
                  element.value = savedValue.value;
                } else if (savedValue && savedValue.type === "time") {
                  element.value = convertTime(savedValue.value);
                }
                if (element.parentNode.classList.contains("grow-with-input")) {
                  element.parentNode.dataset.replicatedValue = savedValue;
                }
              }
            });
          }
        } else {
          // it is a single value
          const elements = document.getElementsByName(key);
          if (elements) {
            elements.forEach((element, i) => {
              if (element.type === "checkbox") {
                if (element.value === td[key]) {
                  element.checked = true;
                }
              } else {
                if (element.classList.contains("relativeDateInput") && !isNaN(Date.parse(savedValue)) && element.type !== "date") {
                  element.value = formatDate(savedValue);
                  // console.log("element.type inside", element.name, element.type, savedValue);
                } else if (element.classList.contains("relativeTimeInput")) {
                  element.value = convertTime(savedValue);
                } else {
                  element.value = savedValue;
                }
                if (element.parentNode.classList.contains("grow-with-content") || element.parentNode.classList.contains("grow-with-input")) {
                  element.parentNode.dataset.replicatedValue = td[key];
                }
                if (element.hasAttribute("data-linked-value") && td[key]) {
                  const ele = document.querySelector(`[name="${element.dataset.linkedValue}"][data-linked-value="${key}"]`);
                  ele.value = td[key];
                  ele.checked = true;
                }
              }
            });
          }
        }
      }
    }
  }
};

export const getFilteredDelegateData = (id, getDelegateData) => {
  const [filteredData] =
    getDelegateData &&
    getDelegateData?.delegateTo
      ?.filter((el) => el?._id === id)
      ?.map((item) => {
        return {
          id: item?._id,
          fullName: item?.fullName || "",
          email: item?.email || "",
          phoneNumber: item?.mobilePhoneNumber?.phoneNumber || "",
          licenseId: item?.licenseId || "",
          brokerageName: item?.brokerageData?.brokerageName || "",
          brokerageAddress: item?.brokerageData?.brokerageAddress || "",
          brokerageLogo: item?.brokerageData?.brokerageLogo || "",
          brokerageLicenseNumber: item?.brokerageData?.brokerageLicenseNumber || "",
          brokerageFaxNumber: item?.brokerageData?.brokerageFaxNumber || "",
          resureFirmId: item?.brokerageData?.brokerageId?.length === 24 ? String(item?.brokerageData?.brokerageId) : "",
        };
      });
  return filteredData || {};
};

export const prepareAgent = ({ delegate, delegateAgent = {}, getUserDetailsData = {}, brokerLicenseId = "" }) => {
  const officeDetails = {
    officeLogo: delegate ? delegateAgent?.brokerageLogo || "" : getUserDetailsData?.brokerageData?.brokerageLogo || getUserDetailsData?.brokerageData?.brokerageId?.logo || "",
    name: delegate ? delegateAgent?.brokerageName || "" : getUserDetailsData?.brokerageData?.brokerageName?.trim() || "",
    address: delegate ? delegateAgent?.brokerageAddress || "" : getUserDetailsData?.brokerageData?.brokerageAddress || "",
    agentId: delegate ? delegateAgent?.id || "" : getUserDetailsData?._id || "",
  };

  const agents = [
    {
      personId: delegate ? delegateAgent?.id || null : getUserDetailsData?._id || null,
      fullName: delegate ? delegateAgent?.fullName || "" : getUserDetailsData?.fullName || "",
      email: delegate ? delegateAgent?.email || "" : getUserDetailsData?.email || "",
      resureFirmId: delegate ? delegateAgent?.resureFirmId || "" : getUserDetailsData?.brokerageData?.brokerageId?._id || "",
      firmName: delegate ? delegateAgent?.brokerageName || "" : getUserDetailsData?.brokerageData?.brokerageName || "",
      firmLicense: delegate ? delegateAgent?.brokerageLicenseNumber || "" : getUserDetailsData?.brokerageData?.brokerageLicenseNumber || "",
      license: delegate ? delegateAgent?.licenseId || "" : brokerLicenseId || "",
      address: delegate ? delegateAgent?.brokerageAddress || "" : getUserDetailsData?.brokerageData?.brokerageAddress || "",
      phoneNumber: delegate ? formatPhoneNumber(delegateAgent?.phoneNumber || "") : formatPhoneNumber(getUserDetailsData?.mobilePhoneNumber?.phoneNumber || ""),
      faxNumber: delegate ? formatPhoneNumber(delegateAgent?.brokerageFaxNumber || "", "FAX") : formatPhoneNumber(getUserDetailsData?.brokerageData?.brokerageFaxNumber || "", "FAX"),
    },
  ];

  return { officeDetails, agents };
};

export const steetAddressDetailsPrepare = ({ addressData }) => {
  if(typeof addressData !== "object"){
    return {};
  }
  return { 
    StreetNumber: addressData?.StreetNumber || "",
    StreetName: addressData?.StreetName || "",
    DirPrefix: addressData?.DirPrefix || "",
    DirSuffix: addressData?.DirSuffix || "",
    StreetSuffix: addressData?.StreetSuffix || "",
    UnitLabel: addressData?.UnitLabel || "",
    UnitNumber: addressData?.UnitNumber || "",
    City: addressData?.City || "",
    State: addressData?.State || "",
    ZipCode: addressData?.ZipCode || "",
  };
};
