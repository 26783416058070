import { Tag, Tooltip, Typography } from "antd";
import React from "react";
import { FaMedal } from "react-icons/fa";
import styles from "../styles/Client.module.css";
import { TitleDetails } from "./TitleDetails";
import { RelationDetails } from "./RelationDetails";
import { UsergroupAddOutlined, PlusCircleFilled } from "@ant-design/icons";
import { capitalizeStr } from "../../Common/utils/extraFunctions";
const Relation = ({ setModelDrawer, selectedClient, personDetails }) => {
  const relationships = Array.isArray(personDetails?.relationships) ? personDetails.relationships : personDetails?.relationship ? [personDetails.relationship] : [];

  const Text = Typography;
  return (
    <Text style={{ display: "flex", alignItems: "center" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title={"Relation"} placement="left">
          <UsergroupAddOutlined className={styles.textAlignment} />
        </Tooltip>
      </div>
      <div className={styles.iconsbox}>
        <div className={styles.iconsclient}>
          <img src="icons/client-details-job.svg" alt=""></img>
        </div>
        <div style={{ marginLeft: "-10%" }} className={styles.userfeild}>
          {" "}
          {(personDetails && personDetails?.relationships && personDetails?.relationships?.length === 0) || !personDetails?.relationships ? (
            "Add Relation"
          ) : (
            <>
              <span>
                {personDetails &&
                  personDetails?.relationships &&
                  personDetails?.relationships?.map((tag) => {
                    return <Tag style={{ background: "rgb(8, 81, 145)", color: "white" }}>{capitalizeStr(tag)}</Tag>;
                  })}
              </span>
            </>
          )}
        </div>
      </div>
      <PlusCircleFilled
        style={{ color: "rgb(8, 81, 145)", fontSize: "1.5rem", marginLeft: "0.5rem", marginRight: "0.9rem" }}
        className="roleBtn"
        onClick={() =>
          setModelDrawer({
            status: true,
            component: <RelationDetails setModelDrawer={setModelDrawer} data={relationships} selectedClient={selectedClient} />,
            title: "Relationships Details",
            toolTip: "Add Relationships",
          })
        }
      />
    </Text>
  );
};

export { Relation };
