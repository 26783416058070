import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, DatePicker, Button, List, Typography, Checkbox } from "antd";
import { clientAction } from "../state/actions";
import moment from "moment";
import { useRef } from "react";
import confirm from "antd/lib/modal/confirm";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { FaBirthdayCake } from "react-icons/fa";

function BirthDateDetails({ data, selectedClient, setModelDrawer }) {
  const dispatch = useDispatch();
  const dateRef = useRef();
  const [form] = Form.useForm();
  const [isFormOpen, setIsFormOpen] = useState(data.length === 0);
  const [editingItem, setEditingItem] = useState(null);
  const { isAddNew } = useSelector((state) => state.client.personDetailReducer);

  const handleEdit = (item) => {
    setEditingItem(item);
    dispatch(clientAction.resetAddNewRequest());
    form.setFieldsValue({
      birthDate: item?.birthDate ? moment(item.birthDate) : null,
      isPrimary: item?.isDefault || false,
    });
    setIsFormOpen(true);
  };

  const handleFormSubmit = (values) => {
    const isPrimary = values.isPrimary || false; // Ensure it's always true/false

    // Update existing names array
    let updatedBirthDates = data?.map((item) => {
      if (item === editingItem) {
        // If this item is being edited, update it with new values
        return {
          ...item,
          birthDate: new Date(values.birthDate).getTime(), // Update full name
          isDefault: editingItem.isDefault ? true : isPrimary, // Preserve default or set new primary
        };
      }
      // For all other items, update their default status if necessary
      return isPrimary ? { ...item, isDefault: false } : item; // Remove previous default if new one is primary
    });

    // If there's no editing item, we're adding a new one
    if (!editingItem) {
      updatedBirthDates = [
        ...updatedBirthDates, // Keep the existing names
        {
          birthDate: new Date(values.birthDate).getTime(),
          isDefault: values?.isPrimary,
          // Set primary if selected
        },
      ];
    }
    // Prepare the final updated data object
    let updatedData = {
      birthDates: updatedBirthDates,
    };

    if (isPrimary) {
      updatedData = {
        ...updatedData,
        birthDate: new Date(values.birthDate).getTime(),
      };
    }

    // Pass updated names array to the parent component
    dispatch(clientAction.updateBirthday(updatedData, data, selectedClient));
    setModelDrawer({ status: false, component: "", title: "", toolTip: "" });
    // onFinish(updatedData);

    // Reset form and close edit view
    form.resetFields();
    setIsFormOpen(false);
    setEditingItem(null);
    dispatch(clientAction?.resetAddNewRequest());
  };

  useEffect(() => {
    if (dateRef.current) {
      dateRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (data.length === 0) {
      form.setFieldsValue({ isPrimary: true });
       return;
     } else {
       form?.resetFields();
       setEditingItem(null);
     }
   }, [isAddNew]);

  useEffect(() => {
    if (data.length === 0) {
      setIsFormOpen(true);
      form.setFieldsValue({ isPrimary: true });
      dispatch(clientAction.addNewRequest())
    }
  }, [data]);
  const showDeleteConfirm = (birthDayToDelete) => {
    confirm({
      title: "Are you sure you want to Remove?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(birthDayToDelete);
      },
    });
  };

  const handleDelete = (birthDayToDelete) => {
    let updatedBirthDates = data.filter((item) => item._id !== birthDayToDelete._id); // Remove the deleted item

    let newPrimary = null;

    if (birthDayToDelete.isDefault && updatedBirthDates.length > 0) {
      // If the deleted name was default, assign the next available one as default
      updatedBirthDates[0] = { ...updatedBirthDates[0], isDefault: true };
      newPrimary = updatedBirthDates[0]; // Capture the new default name
    }

    // Prepare the payload with updated names and new primary details (if changed)
    let updatedData = { birthDates: updatedBirthDates };

    if (newPrimary) {
      updatedData = {
        ...updatedData,
        birthDate: newPrimary.birthDate,
      };
    }

    // Send updated data to the parent
    dispatch(clientAction.updateBirthday(updatedData, data, selectedClient));

    setModelDrawer({ status: false, component: "", title: "", toolTip: "" });
  };
 
  const renderForm = () => {
    return (
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} onFinish={handleFormSubmit} autoComplete="off">
        <Form.Item label="Birthday" name="birthDate" rules={[{ required: true, message: "Please Enter Birth Date!" }]}>
          <DatePicker
            ref={dateRef}
            inputReadOnly={true}
            allowClear
            autoFocus
            // disabledDate={(d) => !d || d.isBefore(minimum)}
            placeholder={editingItem?.birthDate ? [("0" + (new Date(editingItem?.birthDate).getMonth() + 1)).slice(-2), ("0" + new Date(editingItem?.birthDate).getDate()).slice(-2), new Date(editingItem?.birthDate).getFullYear()].join("-") : "Select Birthday"}
            style={{ width: "300px", cursor: "pointer", textAlign: "center" }}
            bordered={true}
            disabledDate={(current) => {
              return current.valueOf() >= new Date();
            }}
            format="MM-DD-YYYY"
          />
        </Form.Item>
        {!editingItem?.isDefault && (
          <Form.Item name="isPrimary" valuePropName="checked" disabled={data.length === 0} wrapperCol={{ offset: 8, span: 16 }}>
            <Checkbox disabled={data.length === 0 || editingItem?.isDefault}>Make this as primary name</Checkbox>
          </Form.Item>
        )}
        <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
          <div style={{ display: "flex", justifyContent: "left" }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
            <Button
              onClick={() => {
                setIsFormOpen(false);
                setEditingItem(null);
                dispatch(clientAction.resetAddNewRequest());
              }}
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };

  return (
    <>
      {!(isFormOpen || isAddNew) && (
        <List
          bordered={false}
          size={"default"}
          dataSource={[...data].sort((a, b) => (b.isDefault ? 1 : -1))}
          renderItem={(item) => (
            <List.Item style={{ cursor: "pointer" }}>
              <Typography.Text strong style={{ fontSize: "18px" }}>
                <FaBirthdayCake style={{ marginRight: "10px" }} />
                {[("0" + (new Date(item?.birthDate).getMonth() + 1)).slice(-2), ("0" + new Date(item?.birthDate).getDate()).slice(-2), new Date(item?.birthDate).getFullYear()].join("-")}
                <span style={{ fontSize: "14px", color: "grey" }}>{item?.isDefault ? " (Default)" : ""}</span>
              </Typography.Text>

              <div>
                <EditOutlined onClick={() => handleEdit(item)} />
                {data?.length > 1 && <DeleteOutlined style={{ marginLeft: "10px" }} onClick={() => showDeleteConfirm(item)} />}
              </div>
            </List.Item>
          )}
        />
      )}
      {!isAddNew && editingItem && (
        <div>
          <Typography.Text strong style={{ fontSize: "18px" }}>
            <FaBirthdayCake style={{ marginRight: "10px" }} />
            <Typography.Text strong style={{ fontSize: "18px" }}>
              {[("0" + (new Date(editingItem?.birthDate).getMonth() + 1)).slice(-2), ("0" + new Date(editingItem?.birthDate).getDate()).slice(-2), new Date(editingItem?.birthDate).getFullYear()].join("-")}
              <span style={{ fontSize: "14px", color: "grey" }}>{editingItem?.isDefault ? " (Default)" : ""}</span>
            </Typography.Text>
          </Typography.Text>
        </div>
      )}

      {(isFormOpen || isAddNew) && (
        <>
          <span style={{ display: "flex", justifyContent: "center", fontWeight: "bold", fontSize: "18px", color: "grey", marginBottom: "20px" }}>{isAddNew || data.length === 0 ? "Add" : "Edit"} Birthdate</span>
          {renderForm()}
        </>
      )}
    </>
  );
}
export { BirthDateDetails };
