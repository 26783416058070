import { Col, Form, message, Modal, Select, Spin } from "antd";
import { Option } from "antd/lib/mentions";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TemplateInput = ({ handleOnfocusChange, setOptionalIds, optionalIds, delegate, fieldEmpty }) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const { templateData, templateLoading } = useSelector((state) => state.template);

  const isDisabled = delegate && !fieldEmpty?.delegateBuyer?.id;

  const handleSelectClick = () => {
    if (delegate) {
      if (isDisabled) {
        message.error("Please select a buyer agent before selecting a template.");
      }
      return;
    } 
    if (!templateLoading && (!templateData || templateData.length === 0)) {
      setShowModal(true);
    }
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };

  const handleModalOk = () => {
    setShowModal(false);
    history.push("/tools");
  };

  const handleSelectChange = (value) => {
    setOptionalIds({ ...optionalIds, templateId: value });
  };

  return (
    <Col xs={0} sm={0} md={24} lg={12} xl={12}>
      <Form.Item className="lbl" name="template" label="Offer Template" onFocus={() => handleOnfocusChange({ template: true })}>
        <Select showSearch placeholder="Offer Template (Optional)" optionFilterProp="children" onChange={handleSelectChange} filterOption={(input, option) => option?.children?.toLowerCase()?.includes(input?.toLowerCase())} allowClear onClick={handleSelectClick} notFoundContent="">
          {(templateLoading && !templateData?.length) ? (
            <div style={{ minHeight: "20vh", display: "flex", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
              <Spin size="medium" />
            </div>
          ) : (
            !isDisabled &&
            templateData?.map((ele, index) => (
              <Option key={index} value={ele._id}>
                {ele.templateName}
              </Option>
            ))
          )}
        </Select>
      </Form.Item>

      {showModal && (
        <Modal title={<div style={{ fontWeight: "bold", textAlign: "center", color: "#178DFA" }}>NO OFFER TEMPLATE FOUND</div>} open={showModal} onCancel={handleModalCancel} onOk={handleModalOk} okText="CREATE TEMPLATE" cancelText="CANCEL">
          <p>
            Looks like you don't have any Offer Template in your account. Please go to <span style={{ fontWeight: "bold" }}>Tools</span>, then click on the <span style={{ fontWeight: "bold" }}>Template</span> menu. On the upper left-hand corner, click on the <span style={{ fontWeight: "bold" }}>Create Template</span> button.
          </p>
          <p>
            Or you can refer to the link below to watch a tutorial video:
            <br />
            <a href="https://www.loom.com/share/c70314f257584a0f9d68d8e2085edd32" target="_blank" rel="noopener noreferrer">
              Watch Video
            </a>
          </p>
        </Modal>
      )}
    </Col>
  );
};

export { TemplateInput };
