/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Modal, message, Collapse, Button, Dropdown, Menu, Image, Input, Spin } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ExclamationCircleOutlined, PlusCircleFilled, DeleteTwoTone, MoreOutlined, FolderFilled, InboxOutlined, FolderOpenOutlined } from "@ant-design/icons";
import { clientAction } from "../state/actions";
// import { ImageUploadDetails } from "./ImageUploadDetails";
import { DocumentDetailModal } from "./DocumentDetailModal";
import { Loading } from "../../Common/components/Loading";
import { EmptyData } from "../../Common/components/EmptyData";
import { CaretRightOutlined } from "@ant-design/icons";
import { FaRegFilePdf } from "react-icons/fa";
import Dragger from "antd/lib/upload/Dragger";
import { AiOutlineFileJpg } from "react-icons/ai";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getBuildDataForNewClientdoc, getMaindocumentStatus } from "../utils/helper";
import ClientDocsPanelContent from "./ClientDocsPanelContent";
import { useMediaQuery } from "react-responsive";
import { SignableAndReadOnly } from "../../PropertyComp/components/SignableAndReadOnly";
import { PropertyHandlers } from "../../PropertyComp/state/actions";
import axios from "axios";
import { accountHandle } from "../../Account/state/actions";
import { changeExtensiontoLowerCase } from "../../Common/utils/extraFunctions";
import { ERTSAction } from "../../ErtsModel/state/action";

const { confirm } = Modal;
const { Panel } = Collapse;

function DocumentDetails({ selectedClient, getClientDetail, setSelectedClient, setPage, page }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentDocData, setCurrentDocData] = useState({});
  const [parentClientdocItem, setParentClientdocItem] = useState({});
  const isSmallScreen = useMediaQuery({maxWidth: 480});
  const [modelDrawer, setModelDrawer] = useState({
    status: false,
    component: "",
    title: "",
  });

  const [nestedModalOpen, setNestedModalOpen] = useState({
    isEmailModalVisible: false,
    sendMutipleDoc:false
  });
  const [brokerLicenseId, setBrokerLicenseId] = useState("");
  const { getLicenseData } = useSelector((state) => state.account);

  const { personDocs, personDetails, personDocsLoading, triggerGetPersonDocs } = useSelector((state) => state.client.personDetailReducer, shallowEqual);
  const { getClientLoading } = useSelector(
    (state) => state.client.clientReducer,
    shallowEqual
  );
  const { getUserDetailsData, userId } = useSelector((state) => state.account, shallowEqual);
  let { propertyDocs, propertDocsLoading, updatedPropertyDoc, uploadedPdfDocSuccess } = useSelector((state) => state.PropertyReducer,shallowEqual);

  let unmounted = {
    value: false
  };
  let source = axios.CancelToken.source();

  useEffect(()=>{
    if(selectedClient){
      const params = {
        personId: selectedClient,
        openFor: "CLIENT",
      };
      dispatch(PropertyHandlers.getPropertyDocsFun({params, unmounted, source}));
    }
    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    }
  }, [selectedClient, getClientDetail, updatedPropertyDoc])

  useEffect(() => {
    if (selectedClient) {
      dispatch(clientAction.getPersonDocs({id: selectedClient, unmounted, source, brokerageId: getUserDetailsData?.brokerageData?.brokerageId?._id}));
    }

    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    }
  }, [selectedClient, getClientDetail, triggerGetPersonDocs, uploadedPdfDocSuccess]);

  const showModalOnplus = (element) => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if(!getLicenseData){
      dispatch(accountHandle.getAllLicense({source, unmounted}))
      return () => {
        unmounted.value = true;
        source.cancel("Cancelling in cleanup");
      }
    }
  },[])

  useEffect(() => {
    const brokerLicenseId = getLicenseData?.find((license) => license.idType === "COLORADO STATE LICENSE")?.id || "";
    setBrokerLicenseId(brokerLicenseId?.toUpperCase());
  }, [getLicenseData]);

// create new client docs
  const createNewDocument = ({ createdDocItem, docSecondItem }) => {
    let buildData = getBuildDataForNewClientdoc({ createdDocItem, parentClientdocItem, personDetails, getUserDetailsData, brokerLicenseId, docSecondItem ,personDocs});
    dispatch(clientAction.draftDocumentUlShortning(buildData, location?.pathname, selectedClient));
  };

// upload client docs
  const createDocPayload = () => {
    let buildData = getBuildDataForNewClientdoc({ parentClientdocItem, personDetails, getUserDetailsData, brokerLicenseId,personDocs });
    buildData.sellerIds = buildData?.urlData?.Sellers ;
    buildData.buyerIds = buildData?.urlData?.Buyers;
    return buildData
  };

// view already created client docs
  const viewDocumentFunc = ({ item }) => {
    if(item?.isPdf){
      window.open(item.pdfLink, "_blank")
      return;
    }
    let buildData = {
      builtForId: userId,
      key: userId,
      openFor: ["BDB", "ERTB", "CB_ERTB", "LCAE", "FRASCONA_BDB"].includes(item.contractType) ? "BUYER_AGENT" : "SELLER_AGENT",
      builtForSection: "DOCUMENT",
      documentId: item?._id,
      signable: true,
      token: localStorage.getItem("userAuth"),
    };

    dispatch(clientAction.draftDocumentUlShortning(buildData, location?.pathname, selectedClient));
  };

  //   ---------------------------------new code---------------------------------------------
  const [showInputFileName, setShowInputFileName] = useState(false);
  const [fileNametext, setFileNametext] = useState("");
  const uploadFile = useRef("");
  const [fileSizeOfDocUpload, setFileSizeOfDocUpload] = useState("");
  const [showImageForPreview, setShowImageForPreview] = useState(false);
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [docsModal, setDocsModal] = useState(false);
  const { checkIsMarkEibnerBrokerageError, MEBrokerageAccess, CBFormsAccess, checkIsMarkEibnerBrokerageLoading } = useSelector((state) => state.ERTSReducer);

  // Menu for delete confirm
  const MenuItem = ({ item }) => {
    return (
      <Menu size="large">
        <Menu.Item
          key="draft"
          onClick={(event) => {
            handleDeleteConfirm(item?._id, "item");
          }}
        >
          <DeleteTwoTone
            style={{
              fontSize: 16,
            }}
          />
          <span style={{ color: "04396B" }}> Delete document</span>
        </Menu.Item>
      </Menu>
    );
  };

  function beforeUpload(file) {
    let supportedFormats = file?.type;

    if (supportedFormats?.includes("image")) {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg" || file.type === "image/webp";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG/JPEG/WEBP file!");
      }
      const isLt5M = file.size / 1024 / 1024 < 100;
      if (!isLt5M) {
        message.error("Image must smaller than 100MB!");
      }
      return isJpgOrPng && isLt5M;
    }

    if (supportedFormats?.includes("pdf")) {
      const isPdf = file.type === "application/pdf";
      if (!isPdf) {
        message.error("You can only upload PDF file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 100;
      if (!isLt2M) {
        message.error("File must be smaller than 100MB!");
      }
      return isPdf && isLt2M;
    }
  }

  function bytesToSize(bytes) {
    let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  let handleCustome = (event) => {
    setShowInputFileName(true);
    const fileName = changeExtensiontoLowerCase(event?.file?.name)
    setFileNametext(fileName);
    let fileSize = bytesToSize(event?.file?.size);
    setFileSizeOfDocUpload(fileSize);
    const formData = new FormData();
    formData.append("file", event?.file);
    if (fileName?.includes(".pdf")) {
      formData.append("fileType", "PDF");
    } else {
      formData.append("fileType", "IMAGE");
    }
    uploadFile.current = formData;
  };

  const handleCancelImageForPreview = () => {
    setShowImageForPreview(false);
  };

  const uploadTemp = () => {
    setShowInputFileName(false);
    setDocsModal(false);
    uploadFile.current.append("filename", fileNametext);
    uploadFile.current.append("openFor", "CLIENT");
    uploadFile.current.append("personId", selectedClient);
    dispatch(PropertyHandlers.uploadPropertyDocsFun(uploadFile.current));
    setFileNametext("");
  };

  const handleDeleteConfirm = (docId, source, instruction) => {
    confirm({
      title: `Are you sure you want to delete this ${source}?`,
      content: `Note: This action is irreversible. Deleting this ${source} will permanently remove it from your account.`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        source === "document" ? dispatch(PropertyHandlers.deleteListing({docId: docId, source:"CLIENT_SCREEN"})) : dispatch(PropertyHandlers.removePropertyDocsFun(docId));
      },
      onCancel() {},
    });
  };

  const handleCancelInputFileName = () => {
    setFileNametext("");
    setShowInputFileName(false);
    setFileSizeOfDocUpload("");
  };

  const handleCloseSetFileNameModalConfirm = (docId) => {
    confirm({
      title: `Are you sure you don't want to upload this document?`,
      // content: "Note: This action is irreversible. Deleting this document will permanently remove it from your account.",
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleCancelInputFileName();
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    let checkDocType = true;
    let PasteEventFunction = (event) => {
      let count = 0;
      let error = null;
      var items = (event.clipboardData || event.originalEvent.clipboardData).items;
      for (let index in items) {
        var item = items[index];
        if (item.kind === "file" && count === 0) {
          var blob = item.getAsFile();
          try {
            let d = beforeUpload(blob);
            checkDocType = d;
            if (!d) {
              message.error("Not suppoerted");
            }
          } catch (err) {
            error = err.message;
            break;
          }
          var reader = new FileReader();
          const ide = Math.floor(10000 + Math.random() * 90000);
          const imageName = "pastedImage" + ide;
          var updatedBlob = new File([blob], imageName, { type: blob.type });

          reader.onload = function (event) {
            // Handle image loading
          };
          reader.readAsDataURL(blob);
          count = +count + 1;
        }
      }
      if (error) {
        message.error(error);
      } else if (updatedBlob) {
        if (checkDocType) {
          setShowInputFileName(true);
          const fileName = changeExtensiontoLowerCase(blob?.name)
          setFileNametext(fileName);
          let fileSize = bytesToSize(blob?.size);
          setFileSizeOfDocUpload(fileSize);
          const formData = new FormData();
          formData.append("file", updatedBlob);
          formData.append("fileType", blob?.type?.includes("image") ? "IMAGE" : "PDF");
          // formData.append('filename', fileNametext);
          uploadFile.current = formData;
        }
      }
    };

    let getModal = document.querySelector("#uploaddiv");

    getModal?.addEventListener("paste", PasteEventFunction);
    return () => {
      getModal?.removeEventListener("paste", PasteEventFunction);
    };
  });

  let unmountedMEB = {value: false};
  let sourceAxios = axios.CancelToken.source();

  useEffect(() => {
    dispatch(ERTSAction.checkIsMarkEibnerBrokerage({ unmounted: unmountedMEB, sourceAxios }));
    return () => {
      unmounted.value = true;
      sourceAxios.cancel("Cancelling in cleanup");
    }
  }, []);

  return (
    <>
      <SignableAndReadOnly nestedModalOpen={nestedModalOpen} setNestedModalOpen={setNestedModalOpen} role={["ERTB", "CB_ERTB", "BDB", "LCAE", "FRASCONA_BDB"].includes(currentDocData?.contractType)  ? "BUYER" : "SELLER"} selectedData={currentDocData} source={"CLIENT_DOC"} documentId={currentDocData?._id} selectedClient={selectedClient} docSelected={true} personDocs={personDocs} setSelectedClient={setSelectedClient} setPage={setPage} page={page}/>

      {/* options modal on plus create offer modal  */}
      <DocumentDetailModal modelDrawer={modelDrawer} setModelDrawer={setModelDrawer} setIsModalVisible={setIsModalVisible} dataElement={parentClientdocItem} isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} createNewDocument={createNewDocument} createDocPayload={createDocPayload} personDetails={personDetails} selectedClient={selectedClient} isSmallScreen={isSmallScreen} />
      {(personDocsLoading || getClientLoading) ? (
        <div style={{ minHeight:"218px" }}>
          <Loading />
        </div>
      ) : (
        //   Accordian stats from here
        <>
        <Collapse accordion bordered={false}
          expandIcon={({ isActive }) => (
            <>{isActive ? <FolderOpenOutlined style={{ fontSize: "24px", color: "#6B98C7", marginRight: 10 }} /> : <FolderFilled style={{ fontSize: "24px", color: "#6B98C7", marginRight: 10 }} />}</>
          )}
          style={{ background: "white" }}
          defaultActiveKey={["doraFormsFolder"]}
        >
          <Panel header={<div style={{ fontSize: "18px", fontWeight: "bold"}}>Dora Forms</div>} key="doraFormsFolder">
          {/* Dora Forms Content */}
          {personDocs !== null && personDocs?.length > 0 ? (
            <Collapse accordion bordered={false} expandIcon={({ isActive }) => <CaretRightOutlined style={{ fontSize: "20px", position:'absolute', right: 0, marginTop: "5px" }} rotate={isActive ? 90 : 0} />} style={{ background: "white" }}>
              {personDocs.filter((ele) => !["FRASCONA_BDB", "WFD", "CB_ERTB"].includes(ele.contractType)).map((ele, ind) => {
                const { color, highlightedDocId } = getMaindocumentStatus(ele);
                return (
                  <Panel
                    header={
                      <div style={{ display: "flex", alignItems: 'center' }}>
                        {/* status icon  */}
                        <div
                          style={{
                            width: 18,
                            height: 18,
                            minWidth: 18,
                            minHeight: 18,
                            borderRadius: "50%",
                            marginRight: 10,
                            border: "1px solid black",
                            cursor: "default",
                            backgroundColor: color,
                          }}
                        ></div>
                        <div  style={{ display: "flex", justifyContent: "space-between", width: isSmallScreen ? '80%' : '90%' }}>
                          <div style={{fontSize: isSmallScreen ? '15px': '18px', width:'100%'}}>{ele.name}</div>
                          <div>
                            <PlusCircleFilled
                              onClick={(e) => {
                               e.stopPropagation()
                                showModalOnplus();
                                setParentClientdocItem(ele);
                                return;
                              }}
                              style={{ marginLeft: "20px", fontSize: "20px", color: "#085191", cursor: "pointer" }}
                            />
                          </div>
                        </div>
                      </div>
                    }
                    key={ind}
                  >
                    {/* panel contents starts from here */}
                    <ClientDocsPanelContent dataSource={ele?.createdDocuments} highlightedDocId={highlightedDocId} parentClientdocItem={ele} viewDocumentFunc={viewDocumentFunc} createNewDocument={createNewDocument} handleDeleteConfirm={handleDeleteConfirm} setNestedModalOpen={setNestedModalOpen} setCurrentDocData={setCurrentDocData} />
                  </Panel>
                );
              })}
            </Collapse>
          ) : (
            <EmptyData />
          )}
          </Panel>
          {/* Parent Folder for Brokerage Forms */}
          {checkIsMarkEibnerBrokerageLoading ? (
              <div style={{ textAlign: "center" }}>
                <Spin />
              </div>
            ) : (
              (MEBrokerageAccess || CBFormsAccess) && (
                <Panel header={<div style={{ fontSize: "18px", fontWeight: "bold" }}>{CBFormsAccess ? "CB Forms" : "Brokerage Forms"}</div>} key="brokerageFormsFolder">
                  {personDocs !== null && personDocs?.length > 0 ? (
                    <Collapse
                      accordion
                      bordered={false}
                      expandIcon={({ isActive }) => (
                        <CaretRightOutlined
                          style={{
                            fontSize: "20px",
                            position: "absolute",
                            right: 0,
                            marginTop: "5px",
                          }}
                          rotate={isActive ? 90 : 0}
                        />
                      )}
                      style={{ background: "white" }}
                    >
                      {/* Brokerage Forms Content */}
                      {personDocs
                        .filter((ele) => (CBFormsAccess ? ["CB_ERTB"].includes(ele.contractType) : ["FRASCONA_BDB", "WFD"].includes(ele.contractType)))
                        .map((ele, ind) => {
                          const { color, highlightedDocId } = getMaindocumentStatus(ele);
                          return (
                            <Panel
                              header={
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <div
                                    style={{
                                      width: 18,
                                      height: 18,
                                      minWidth: 18,
                                      minHeight: 18,
                                      borderRadius: "50%",
                                      marginRight: 10,
                                      border: "1px solid black",
                                      cursor: "default",
                                      backgroundColor: color,
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: isSmallScreen ? "80%" : "90%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: isSmallScreen ? "15px" : "18px",
                                        width: "100%",
                                      }}
                                    >
                                      {ele.name}
                                    </div>
                                    <div>
                                      <PlusCircleFilled
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          showModalOnplus();
                                          setParentClientdocItem(ele);
                                        }}
                                        style={{
                                          marginLeft: "20px",
                                          fontSize: "20px",
                                          color: "#085191",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              }
                              key={ind}
                            >
                              <ClientDocsPanelContent dataSource={ele?.createdDocuments} highlightedDocId={highlightedDocId} parentClientdocItem={ele} viewDocumentFunc={viewDocumentFunc} createNewDocument={createNewDocument} handleDeleteConfirm={handleDeleteConfirm} setNestedModalOpen={setNestedModalOpen} setCurrentDocData={setCurrentDocData} />
                            </Panel>
                          );
                        })}
                    </Collapse>
                  ) : (
                    <EmptyData />
                  )}
                </Panel>
              )
            )}
          </Collapse>
        </>
      )}
      <hr />
      {/* PropertyDocs Upload  */}
      {/* Modal to preview Image */}
      <Modal open={showImageForPreview} onCancel={handleCancelImageForPreview} footer={null}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Image src={showImagePreview} preview={true} height={400} />
        </div>
      </Modal>

      {/* Modal to add Input file Name */}
      <Modal
        title={<div style={{ display: "flex", justifyContent: "center", color: "grey", fontWeight: "bold", fontSize: "20px" }}>Enter File Name</div>}
        footer={
          <Button type="primary" onClick={uploadTemp} disabled={fileNametext?.length > 0 ? false : true}>
            Upload
          </Button>
        }
        open={showInputFileName}
        onCancel={handleCloseSetFileNameModalConfirm}
      >
        <div>
          <p>
            <span style={{ fontWeight: "bold" }}>File Name :</span> {fileNametext}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>File Size :</span> {fileSizeOfDocUpload}
          </p>
          <Input
            value={showInputFileName ? fileNametext : ""}
            defaultValue={""}
            placeholder="Enter File Name"
            onChange={(e) => {
              setFileNametext(e.target.value);
            }}
          />
        </div>
      </Modal>
      <div style={{ height: "300px" }}>
        <div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: 10, padding: 3 }}>
          <FolderFilled style={{ fontSize: "24px", color: "#6B98C7" }} />
          <span style={{ color: "", fontSize: "20px", fontWeight: "bold" }}>Other</span>
          <PlusCircleFilled size={25} style={{ position: "relative", fontSize: "20px", cursor: "pointer", color: "#085191" }} onClick={() => setDocsModal(true)} />
          {/* </Upload> */}
        </div>

        {/* documents box  */}
        {propertDocsLoading ? <Loading/>:
        <div style={{ padding: "5px", height: "300px", overflow: "auto", boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"}}>
          {!propertDocsLoading && propertyDocs?.filter((doc) => doc.fileType === "IMAGE").length <= 0}
          {propertyDocs?.length > 0 &&
            propertyDocs
              ?.filter((doc) => doc.fileType === "IMAGE")
              .map((el) => (
                <div style={{ display: "flex", alignItems: "center", margin: "auto", marginBottom: "5px" }} className="property-item">
                  <a target="_blank" href={el.fileUrl} style={{ width: "100%", marginBottom: "0px", fontSize: "18px", color: "black", display: "flex", alignItems: "center", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }} rel="noreferrer">
                    <AiOutlineFileJpg style={{ color: "red", marginRight: "5px" }} />
                    <span style={{ fontSize: "13px", maxWidth: "80%" }}>{el.fileName}</span>
                  </a>

                  <Dropdown trigger={["click", "hover"]} overlay={<MenuItem item={el} />}>
                    <MoreOutlined key="ellipsis" style={{ marginLeft: "10px", color: "black" }} />
                  </Dropdown>
                </div>
              ))}
          {!propertDocsLoading && propertyDocs?.filter((doc) => doc.fileType === "PDF").length <= 0}
          {propertyDocs?.length > 0 &&
            propertyDocs
              ?.filter((doc) => doc.fileType === "PDF")
              .map((el) => (
                <div style={{ display: "flex", alignItems: "center", margin: "auto", marginBottom: "5px" }} className="property-item">
                  <a target="_blank" href={el.fileUrl} style={{ width: "100%", marginBottom: "0px", fontSize: "18px", color: "black", display: "flex", alignItems: "center", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }} rel="noreferrer">
                    <FaRegFilePdf style={{ color: "red", marginRight: "5px" }} />
                    <span style={{ fontSize: "13px", maxWidth: "80%" }}>{el.fileName}</span>
                  </a>

                  <Dropdown trigger={["click", "hover"]} overlay={<MenuItem item={el} />}>
                    <MoreOutlined key="ellipsis" style={{ marginLeft: "10px", color: "hsl(209deg 72% 51%)" }} />
                  </Dropdown>
                </div>
              ))}
          {!propertDocsLoading && propertyDocs?.length <= 0 && (
            <div style={{ width: "100%", marginTop: "20%" }}>
              <EmptyData />
            </div>
          )}
        </div>}
      </div>
      <Modal visible={docsModal} onOk={() => {}} title={<p style={{ textAlign: "center", color: "grey", fontSize: "19px" }}>Upload File</p>} footer={false} onCancel={() => setDocsModal(false)}>
        <div id="uploaddiv" style={{ width: "80%", margin: "auto" }}>
          <Dragger progress={false} style={{ width: "400px" }} showUploadList={false} customRequest={handleCustome} beforeUpload={beforeUpload}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Paste, Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">Support for a single document/pdf upload. Strictly prohibit from uploading company data or other band files</p>
          </Dragger>
        </div>
      </Modal>
    </>
  );
}
export { DocumentDetails };
